import { useTranslation } from "react-i18next";
import style from "./Button.module.scss";

const Button = ({
  onClick,
  title = "buttons",
  className,
  isCommingSoon = false,
  isDisable = false,
  href = false,
  target,
  ...props
}) => {
  const { t } = useTranslation();
  if (href) {
    return (
      <a
        href={href}
        target={target}
        onClick={onClick}
        className={`${style.btn} ${
          isDisable ? style.disable : ""
        } ${className}`}
      >
        {title}
        <span>{title}</span>
      </a>
    );
  }

  if (isCommingSoon) {
    return (
      <div
        className={`${style.btn} ${isDisable ? style.disable : ""} ${
          style.commingSoon
        } ${className}`}
      >
        {t("btn.soon")}
        <span>{t("btn.soon")}</span>
      </div>
    );
  } else {
    return (
      <button
        onClick={onClick}
        className={`${style.btn} ${
          isDisable ? style.disable : ""
        } ${className}`}
      >
        {title}
        <span>{title}</span>
      </button>
    );
  }
};

export default Button;
