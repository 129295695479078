import { Outlet } from 'react-router-dom';
import style from './Article.module.scss';
import Related from './modules/Related/Related';
import { useMediaQuery } from 'usehooks-ts';
import RelatedMobile from './modules/Related/RelatedMobile';

const Article = () => {
  const isMobile = useMediaQuery('(max-width:767px)');

  return (
    <div className={style.article}>
      <Outlet />

      {isMobile ? <RelatedMobile /> : <Related />}
    </div>
  );
};

export default Article;
