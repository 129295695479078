
const LinktreeSvg = () => <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_531_2260)">
    <path d="M9.85678 1.32415C9.44298 0.57998 8.36783 0.57998 7.95434 1.32415L0.8406 14.2253C0.509622 14.8869 1.00594 15.6311 1.75041 15.6311H6.54791V20.5102C6.54791 21.0065 6.96171 21.42 7.45803 21.42H10.2703C10.7666 21.42 11.1801 21.0065 11.1801 20.5102V15.6311H9.85678C9.27764 15.6311 8.86415 15.2176 8.78133 14.7213C8.78133 14.5559 8.78133 14.3906 8.86415 14.2234L12.8347 7.0287L9.85678 1.32415Z" fill="#383737" />
    <path d="M16.1432 1.32415C16.557 0.57998 17.6321 0.57998 18.0456 1.32415L25.1593 14.2253C25.4903 14.8869 24.994 15.6311 24.2495 15.6311H19.5345V20.5102C19.5345 21.0065 19.1211 21.42 18.6238 21.42H15.6469C15.1505 21.42 14.737 21.0065 14.737 20.5102V15.6311H16.0607C16.6395 15.6311 17.053 15.2176 17.1358 14.7213C17.1358 14.5559 17.1358 14.3906 17.053 14.2234L13.0828 7.03054L16.1432 1.32415Z" fill="#383737" />
  </g>
  <defs>
    <clipPath id="clip0_531_2260">
      <rect width="25" height="20.84" fill="white" transform="translate(0.5 0.579987)" />
    </clipPath>
  </defs>
</svg>

export default LinktreeSvg