import style from "./SocialLink.module.scss"

const SocialLink = ({ linkTo = '', icon = '', className, isDisable = false, ...props }) => {
  return (
    <a href={linkTo} target={'_blank'} className={`${style.btn} ${isDisable ? style.disable : ''} ${className}`}>
      {icon}
    </a>
  )
}

export default SocialLink