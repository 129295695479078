import style from './ArticleCard.module.scss';
import ArrowSvg from '../../assets/img/home/slider/arrow';

const ArticleCard = ({ onClick, img, title, className }) => {
  return (
    <div onClick={onClick} className={`${style.card} ${className}`}>
      <div className={style.card__img}>
        <img src={img} alt='' />
      </div>
      <div className={style.card__title}>
        <span dangerouslySetInnerHTML={{ __html: title }}></span>
        <span className={style.btn}>{ArrowSvg()}</span>
      </div>
    </div>
  );
};

export default ArticleCard;
