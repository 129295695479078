import en from "./translate/en.json";
import ru from "./translate/ru.json";
import it from "./translate/it.json";
import ko from "./translate/ko.json";
import fr from "./translate/fr.json";
import es from "./translate/es.json";
import de from "./translate/de.json";
import hi from "./translate/hi.json";
import pt from "./translate/pt.json";
import zh from "./translate/zh.json";
import ja from "./translate/ja.json";

import { initReactI18next } from "react-i18next";
import i18next from "i18next";

const resources = {
  en: {
    translation: en,
  },
  ru: {
    translation: ru,
  },
  it: {
    translation: it,
  },
  ko: {
    translation: ko,
  },
  fr: {
    translation: fr,
  },
  es: {
    translation: es,
  },
  de: {
    translation: de,
  },
  hi: {
    translation: hi,
  },
  pt: {
    translation: pt,
  },
  zh: {
    translation: zh,
  },
  ja: {
    translation: ja,
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: JSON.parse(localStorage.getItem("language")),
  fallbackLng: "en",
});

export default i18next;
