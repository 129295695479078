
const InstagraSvg = () => <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_531_2252)">
    <path d="M16.0448 0H5.95519C2.67144 0 0 2.67144 0 5.95519V16.0449C0 19.3285 2.67144 22 5.95519 22H16.0449C19.3285 22 22 19.3285 22 16.0449V5.95519C22 2.67144 19.3285 0 16.0448 0V0ZM11 17.0154C7.683 17.0154 4.98453 14.317 4.98453 11C4.98453 7.683 7.683 4.98453 11 4.98453C14.317 4.98453 17.0154 7.683 17.0154 11C17.0154 14.317 14.317 17.0154 11 17.0154V17.0154ZM17.1593 6.40317C16.179 6.40317 15.3818 5.6059 15.3818 4.62568C15.3818 3.64546 16.179 2.84802 17.1593 2.84802C18.1395 2.84802 18.9369 3.64546 18.9369 4.62568C18.9369 5.6059 18.1395 6.40317 17.1593 6.40317Z" fill="#383737" />
    <path d="M11 6.27426C8.39436 6.27426 6.27429 8.39416 6.27429 11C6.27429 13.6056 8.39436 15.7257 11 15.7257C13.6058 15.7257 15.7257 13.6056 15.7257 11C15.7257 8.39416 13.6058 6.27426 11 6.27426V6.27426Z" fill="#383737" />
    <path d="M17.1593 4.13791C16.8904 4.13791 16.6715 4.35678 16.6715 4.62567C16.6715 4.89456 16.8904 5.11343 17.1593 5.11343C17.4283 5.11343 17.6472 4.89473 17.6472 4.62567C17.6472 4.35661 17.4283 4.13791 17.1593 4.13791Z" fill="#383737" />
  </g>
  <defs>
    <clipPath id="clip0_531_2252">
      <rect width="22" height="22" fill="white" />
    </clipPath>
  </defs>
</svg>

export default InstagraSvg