import SocialLink from '../SocialLink/SocialLink';
import style from './Header.module.scss';
import LangSelect from '../LangSelect/LangSelect';
import { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { useLocation, useNavigate } from 'react-router-dom';
import { ImgWebp } from '../../helpers/imgwebp';

// img and svg imports
import logoPng from '@assets/img/header/logo.png';
import logoPng2x from '@assets/img/header/logo@2x.png';
import logoWebp from '@assets/img/header/logo.webp';
import TwitterSvg from '../../assets/img/social/twitterSvg';
import TelegramSvg from '@assets/img/social/telegramSvg';
import InstagramSvg from '@assets/img/social/instagramSvg';
import DiscordSvg from '@assets/img/social/discordSvg';
import LinktreeSvg from '@assets/img/social/linktreeSvg';
import { useTranslation } from 'react-i18next';
import Button from '../Button/Button';

const Header = () => {
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:767px)');
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation();

  const scrollToSectionHelp = (section) => {
    const item = document.querySelector(`#${section}`);
    const body = document.querySelector('body');
    setIsBurgerOpen(false);
    if (body.classList.contains('lock')) {
      body.classList.remove('lock');
    }

    const elementPosition = item.getBoundingClientRect().top;
    const offsetPosition = elementPosition - 75;

    window.scrollBy({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  const scrollToSection = (section) => {
    const body = document.querySelector('body');
    setIsBurgerOpen(false);
    if (body.classList.contains('lock')) {
      body.classList.remove('lock');
    }
    if (location.pathname !== '/') {
      navigate('/');
      setTimeout(() => {
        const item = document.querySelector('#about');

        window.scrollTo({
          top: item.offsetTop,
          behavior: 'smooth',
        });
      }, 100);
    } else {
      const item = document.querySelector('#about');

      window.scrollTo({
        top: item.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  const openMenu = () => {
    const body = document.querySelector('body');
    if (body.classList.contains('lock')) {
      body.classList.remove('lock');
    } else {
      body.classList.add('lock');
    }
    setIsBurgerOpen(!isBurgerOpen);
  };

  const header = useRef();
  const stickyHeader = () => {
    if (window.innerWidth > 991) {
      if (window.scrollY >= 50) {
        header.current.classList.add(style.sticky);
      } else {
        if (header.current.classList.contains(style.sticky)) {
          header.current.classList.remove(style.sticky);
        }
      }
    } else {
      if (window.scrollY >= 50) {
        header.current.classList.add(style.sticky);
      } else {
        if (header.current.classList.contains(style.sticky)) {
          header.current.classList.remove(style.sticky);
        }
      }
    }
  };

  useEffect(() => {
    stickyHeader();
    window.addEventListener('scroll', stickyHeader);
  });

  const toPage = (page) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    if (isBurgerOpen) {
      openMenu();
    }
    return navigate(`${page}`);
  };

  if (location.pathname !== '/buy' && location.pathname !== '/buy-elrond') {
    if (!isMobile) {
      return (
        <div ref={header} className={style.header}>
          <div className='container'>
            <div className={style.headerBody}>
              <div className={style.headerBody__logo}>
                {/* <img src={logoPng} srcSet={logoPng2x} alt="" /> */}
                <ImgWebp src={logoPng} src2x={logoPng2x} srcWebp={logoWebp} />
              </div>
              <div className={style.headerBody__menu}>
                <div
                  onClick={() => {
                    toPage('/');
                  }}
                  className={style.headerBody__menuItem}
                >
                  {t('header.home')}
                </div>
                <div
                  onClick={() => toPage('token')}
                  className={style.headerBody__menuItem}
                >
                  {t('header.token')}
                </div>
                <div
                  onClick={() => toPage('pikaverse')}
                  className={style.headerBody__menuItem}
                >
                  {/* {t('header.pikaverse')} */}
                  Metaverse
                </div>
                <div
                  onClick={() => toPage('blog')}
                  className={style.headerBody__menuItem}
                >
                  Blog
                </div>
                <div
                  onClick={() => toPage('buy')}
                  className={`${style.headerBody__menuItem} ${style.active}`}
                >
                  buy
                </div>
                <div className={`${style.headerBody__menuItem}`}>
                  <LangSelect />
                </div>
              </div>
              <div className={style.headerBody__links}>
                <SocialLink
                  className={style.headerBody__linksItem}
                  linkTo='https://twitter.com/pikamooncoin'
                  icon={<TwitterSvg />}
                />
                <SocialLink
                  className={style.headerBody__linksItem}
                  linkTo='https://t.me/pikamooncoins'
                  icon={<TelegramSvg />}
                />
                <SocialLink
                  className={style.headerBody__linksItem}
                  linkTo='https://instagram.com/pikamooncoin_'
                  icon={<InstagramSvg />}
                />
                <SocialLink
                  className={style.headerBody__linksItem}
                  linkTo='https://discord.gg/QFHbbKQed9'
                  icon={<DiscordSvg />}
                />
                <SocialLink
                  className={style.headerBody__linksItem}
                  linkTo='https://linktr.ee/pikamoon'
                  icon={<LinktreeSvg />}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div
            ref={header}
            className={`
          ${style.header}
          ${isBurgerOpen ? style.open : ''}
          `}
          >
            <div className='container'>
              <div className={style.headerBody}>
                <div className={style.headerBody__logo}>
                  <ImgWebp src={logoPng} src2x={logoPng2x} srcWebp={logoWebp} />
                </div>
                <div className={style.headerBody__links}>
                  <SocialLink
                    className={style.headerBody__linksItem}
                    linkTo='https://twitter.com/pikamooncoin'
                    icon={<TwitterSvg />}
                  />
                  <SocialLink
                    className={style.headerBody__linksItem}
                    linkTo='https://t.me/pikamooncoins'
                    icon={<TelegramSvg />}
                  />
                  <SocialLink
                    className={style.headerBody__linksItem}
                    linkTo='https://instagram.com/pikamooncoin_'
                    icon={<InstagramSvg />}
                  />
                  <SocialLink
                    className={style.headerBody__linksItem}
                    linkTo='https://discord.gg/QFHbbKQed9'
                    icon={<DiscordSvg />}
                  />
                  <SocialLink
                    className={style.headerBody__linksItem}
                    linkTo='https://linktr.ee/pikamoon'
                    icon={<LinktreeSvg />}
                  />
                </div>
                <div
                  onClick={openMenu}
                  className={`
              ${style.headerBody__burger} 
              ${isBurgerOpen ? style.open : ''}`}
                >
                  <div className={style.headerBody__burgerItem}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${style.headerBody__menu} ${
              isBurgerOpen ? style.open : ''
            }`}
          >
            <div className={`${style.headerBody__menuItem}`}>
              <LangSelect />
            </div>
            <div
              onClick={() => toPage('/')}
              className={style.headerBody__menuItem}
            >
              {t('header.home')}
            </div>
            <div
              onClick={() => toPage('token')}
              className={style.headerBody__menuItem}
            >
              {t('header.token')}
            </div>
            <div
              onClick={() => toPage('pikaverse')}
              className={style.headerBody__menuItem}
            >
              METAVERSE
            </div>
            <div
              onClick={() => toPage('blog')}
              className={style.headerBody__menuItem}
            >
              BLOG
            </div>
            <div
              onClick={() => toPage('buy')}
              className={`${style.headerBody__menuItem} ${style.active}`}
            >
              BUY
            </div>
          </div>
        </>
      );
    }
  } else {
    if (!isMobile) {
      return (
        <div ref={header} className={`${style.header} ${style.help}`}>
          <div className='container'>
            <div className={style.headerBody}>
              <div className={style.headerBody__links}>
                <SocialLink
                  className={style.headerBody__linksItem}
                  linkTo='https://twitter.com/pikamooncoin'
                  icon={<TwitterSvg />}
                />
                <SocialLink
                  className={style.headerBody__linksItem}
                  linkTo='https://t.me/pikamooncoin'
                  icon={<TelegramSvg />}
                />
                <SocialLink
                  className={style.headerBody__linksItem}
                  linkTo='https://instagram.com/pikamooncoin_'
                  icon={<InstagramSvg />}
                />
                <SocialLink
                  className={style.headerBody__linksItem}
                  linkTo='https://discord.gg/QFHbbKQed9'
                  icon={<DiscordSvg />}
                />
                <SocialLink
                  className={style.headerBody__linksItem}
                  linkTo='https://linktr.ee/pikamoon'
                  icon={<LinktreeSvg />}
                />
              </div>

              <div
                onClick={() => toPage('/')}
                className={style.headerBody__logo}
              >
                <ImgWebp src={logoPng} src2x={logoPng2x} srcWebp={logoWebp} />
              </div>

              <div className={style.headerBody__right}>
                <div className={`${style.headerBody__menuItem}`}>
                  <LangSelect />
                </div>

                <Button
                  onClick={() => scrollToSectionHelp('phase1')}
                  className={`${style.headerBody__rightBtn}`}
                  title={t('help.header.buynow')}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div
            ref={header}
            className={`
            ${style.help}
          ${style.header}
          ${isBurgerOpen ? style.open : ''}
          `}
          >
            <div className='container'>
              <div className={style.headerBody}>
                <div
                  className={`${style.headerBody__menuItem} ${style.headerBody__left}`}
                >
                  <LangSelect />
                </div>
                <div
                  onClick={() => toPage('/')}
                  className={style.headerBody__logo}
                >
                  <ImgWebp src={logoPng} src2x={logoPng2x} srcWebp={logoWebp} />
                </div>
                <div className={style.headerBody__right}>
                  <div
                    onClick={openMenu}
                    className={`
              ${style.headerBody__burger} 
              ${isBurgerOpen ? style.open : ''}`}
                  >
                    <div className={style.headerBody__burgerItem}>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${style.headerBody__menu} ${style.help} ${
              isBurgerOpen ? style.open : ''
            }`}
          >
            <div className={style.headerBody__links}>
              <SocialLink
                className={style.headerBody__linksItem}
                linkTo='https://twitter.com/pikamooncoin'
                icon={<TwitterSvg />}
              />
              <SocialLink
                className={style.headerBody__linksItem}
                linkTo='https://t.me/pikamooncoins'
                icon={<TelegramSvg />}
              />
              <SocialLink
                className={style.headerBody__linksItem}
                linkTo='https://instagram.com/pikamooncoin_'
                icon={<InstagramSvg />}
              />
              <SocialLink
                className={style.headerBody__linksItem}
                linkTo='https://discord.gg/QFHbbKQed9'
                icon={<DiscordSvg />}
              />
              <SocialLink
                className={style.headerBody__linksItem}
                linkTo='https://linktr.ee/pikamoon'
                icon={<LinktreeSvg />}
              />
            </div>
            <div
              onClick={() => scrollToSectionHelp('phase1')}
              className={style.headerBody__menuItem}
            >
              {t('help.header.menu.phase1')}
            </div>
            <div
              onClick={() => scrollToSectionHelp('presale')}
              className={style.headerBody__menuItem}
            >
              {t('help.header.menu.presale')}
            </div>
            <div
              onClick={() => scrollToSectionHelp('how')}
              className={style.headerBody__menuItem}
            >
              {t('help.header.menu.howToBuy')}
            </div>
            <div
              onClick={() => scrollToSectionHelp('purchase')}
              className={style.headerBody__menuItem}
            >
              {t('help.header.menu.howToClaim')}
            </div>
            <div
              onClick={() => scrollToSectionHelp('question')}
              className={`${style.headerBody__menuItem}`}
            >
              {t('help.header.menu.questions')}
            </div>
          </div>
        </>
      );
    }
  }
};

export default Header;
