import style from './Related.module.scss';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

// img imports
import arrow from '@assets/img/home/slider/arrow';
import ArticleCard from '../../../../components/ArticleCard/ArticleCard';
import { articleList } from '../../articles/articles-list';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'usehooks-ts';

const Related = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width:991px)');

  return (
    <div className={style.related}>
      <div className='container'>
        <div className={`${style.related__title} title_lg`}>
          related articles
        </div>
        <div className={style.relatedBody}>
          <Swiper
            modules={[Navigation]}
            grabCursor
            slidesPerView={isMobile ? 1 : 2}
            spaceBetween={40}
            className={style.relatedBody__slider}
            navigation={{
              prevEl: '._slider-related-prev',
              nextEl: '._slider-related-next',
            }}
          >
            {articleList.map(
              (item, index) =>
                `/article/${item.path}` !== location.pathname && (
                  <SwiperSlide key={index}>
                    <ArticleCard
                      onClick={() => {
                        navigate(`/article/${item.path}`);
                      }}
                      img={item.img}
                      title={item.title}
                      className={style.relatedBody__item}
                    />
                  </SwiperSlide>
                )
            )}
          </Swiper>
        </div>
        <div className={style.relatedBtns}>
          <div className={`${style.relatedBtns__item} _slider-related-prev`}>
            {arrow()}
          </div>
          <div className={`${style.relatedBtns__item} _slider-related-next`}>
            {arrow()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Related;
