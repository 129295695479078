const ArrowSvg = () => (
  <svg
    width='26'
    height='25'
    viewBox='0 0 26 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.74526 8.38245C7.00875 8.03991 7.06145 7.61832 6.69256 7.30213C6.37637 7.03863 5.92843 7.06498 5.61224 7.30213C5.0062 7.77642 4.40016 8.2507 3.87318 8.77769C3.00365 9.67357 2.21317 10.5958 1.39634 11.518C1.08015 11.7025 0.974749 12.1241 1.15919 12.4139L1.26459 12.572C2.42396 14.4692 4.18937 15.813 5.66493 17.4466C5.74398 17.5257 5.98113 17.4993 6.08652 17.4466C6.32367 17.3412 6.40272 17.1041 6.37637 16.8406C6.29732 16.0238 5.92843 15.3387 5.42779 14.7326C4.8481 14.0739 4.29477 13.3888 3.71508 12.7301C3.74143 12.7301 3.79413 12.7301 3.82048 12.7564C5.0589 12.8355 6.29732 12.9409 7.53574 12.9409C9.51194 12.9145 11.5145 12.8091 13.4907 12.7564C17.0479 13.3361 20.6841 13.0199 24.2939 13.1253C24.452 13.1253 24.7155 12.9409 24.7946 12.8355C24.9527 12.572 24.7419 12.3612 24.452 12.2031C23.4507 11.6761 22.2123 11.439 20.9739 11.3863C17.9964 11.2282 14.9926 11.0174 11.9888 11.1755C11.6199 11.1491 11.251 11.1228 10.8821 11.1228C8.61606 11.0437 6.35002 11.0174 4.08397 11.2018C5.03255 10.3323 5.95478 9.43642 6.74526 8.38245Z'
      fill='#383737'
    />
  </svg>
);

export default ArrowSvg;
