import img1 from '../../..//assets/img/blog/img01.jpg';
import img2 from '../../..//assets/img/blog/img02.jpg';
import img3 from '../../..//assets/img/blog/img03.jpg';
import img4 from '../../..//assets/img/blog/img04.jpg';

export const articleList = [
  {
    img: img1,
    title: 'Pikamoon Token: ICO and Token Allocation Explained...',
    path: 'token',
  },
  {
    img: img2,
    title: 'Pikamoon Partners With Transak to Make GameFi...',
    path: 'transak',
  },
  {
    img: img3,
    title: 'Pikamoon Partners with Kevuru Games to Create...',
    path: 'partners',
  },
  {
    img: img4,
    title: 'Pikamoon NFTs <br /> Explained...',
    path: 'nft',
  },
];
