
const TelegramSvg = () => <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_531_2249)">
    <path d="M10.3093 16.3135L9.89578 22.1302C10.4874 22.1302 10.7437 21.876 11.051 21.5708L13.8249 18.9198L19.5729 23.1292C20.627 23.7167 21.3697 23.4073 21.6541 22.1594L25.427 4.48021L25.4281 4.47917C25.7624 2.92084 24.8645 2.31146 23.8374 2.69376L1.66036 11.1844C0.146816 11.7719 0.169733 12.6156 1.40307 12.9979L7.07286 14.7615L20.2426 6.52084C20.8624 6.11042 21.426 6.33751 20.9624 6.74792L10.3093 16.3135Z" fill="#383737" />
  </g>
  <defs>
    <clipPath id="clip0_531_2249">
      <rect width="25" height="25" fill="white" transform="translate(0.5 0.5)" />
    </clipPath>
  </defs>
</svg>

export default TelegramSvg