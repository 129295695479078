import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import Article from './screens/Article/Article';

const Ui = React.lazy(() => import('./screens/_ui/_ui'));
const Home = React.lazy(() => import('./screens/Home/Home'));
const TokenScreen = React.lazy(() => import('./screens/Tokens/Tokens'));
const PikaverseScreen = React.lazy(() =>
  import('./screens/Pikaverse/Pikaverse')
);
const GiveawayScreen = React.lazy(() => import('./screens/Giveaway/Giveaway'));
const PrivacyScreen = React.lazy(() => import('./screens/Privacy/Privacy'));
const PolicyScreen = React.lazy(() => import('./screens/Policy/Policy'));
const DashboardScreen = React.lazy(() =>
  import('./screens/Dashboard/Dashboard')
);
const BlogScreen = React.lazy(() => import('./screens/Blog/Blog'));
const CommingSoon = React.lazy(() =>
  import('./screens/CommingSoon/CommingSoon')
);

const TokenArticle = React.lazy(() =>
  import('./screens/Article/articles/Token/Token')
);
const NftArticle = React.lazy(() =>
  import('./screens/Article/articles/Nft/Nft')
);
const TransakArticle = React.lazy(() =>
  import('./screens/Article/articles/Transak/Transak')
);
const PartnersArticle = React.lazy(() =>
  import('./screens/Article/articles/Partners/Partners')
);

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '*',
        element: <Home />,
      },
      {
        path: '/',
        element: <Home />,
      },
      {
        path: 'token',
        element: <TokenScreen />,
      },
      {
        path: 'pikaverse',
        element: <PikaverseScreen />,
      },
      {
        path: 'giveaway',
        element: <GiveawayScreen />,
      },
      {
        path: 'privacy',
        element: <PrivacyScreen />,
      },
      {
        path: 'policy',
        element: <PolicyScreen />,
      },
      {
        path: 'buy',
        element: <DashboardScreen />,
      },
      {
        path: 'buy-elrond',
        element: <DashboardScreen />,
      },
      {
        path: 'blog',
        element: <BlogScreen />,
      },
      {
        path: 'article',
        element: <Article />,
        children: [
          { path: 'token', element: <TokenArticle /> },
          { path: 'nft', element: <NftArticle /> },
          { path: 'transak', element: <TransakArticle /> },
          { path: 'partners', element: <PartnersArticle /> },
        ],
      },
      {
        path: 'ui',
        element: <Ui />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
