
const downArrowSvg = () => <svg width="17" height="11" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_405_1106)">
    <path d="M8.49999 10.1875C8.30018 10.1875 8.10018 10.1111 7.94764 9.95858L0.135144 2.14608C-0.170129 1.84081 -0.170129 1.34647 0.135144 1.04139C0.440417 0.736316 0.934753 0.736121 1.23983 1.04139L8.49999 8.30155L15.7601 1.04139C16.0654 0.736121 16.5598 0.736121 16.8648 1.04139C17.1699 1.34667 17.1701 1.841 16.8648 2.14608L9.05233 9.95858C8.89979 10.1111 8.69979 10.1875 8.49999 10.1875Z" fill="#383737" />
  </g>
  <defs>
    <clipPath id="clip0_405_1106">
      <rect width="17" height="11" fill="white" />
    </clipPath>
  </defs>
</svg>

export default downArrowSvg