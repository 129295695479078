import style from './LangSelect.module.scss';
import { useState } from 'react';
import useLocalStorage from '../../hooks/use-localstorage';
import i18next from '../../i18n';
import { useLocation } from 'react-router-dom';

// svg imports
import DownArrowSvg from '@assets/img/icons/down-arrowSvg';

const LangSelect = ({ title = '', isDisable = false, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const [language, setLanguage] = useLocalStorage('language', 'en');

  const handleLanguageChange = (lang) => {
    i18next.changeLanguage(lang);
    setLanguage(lang);
    setIsOpen(false);
  };

  return (
    <div
      className={`
    ${style.select} 
    ${isDisable ? style.disabled : ''} 
    ${isOpen ? style.open : ''}`}
    >
      <div
        onClick={() => (!isDisable ? setIsOpen(!isOpen) : '')}
        className={`${style.select__btn}`}
      >
        {language}
        <DownArrowSvg />
      </div>
      <div
        onMouseLeave={() => setIsOpen(false)}
        className={`${style.selectDropdown} ${
          location.pathname === '/help' ? style.help : ''
        }`}
      >
        <div
          onClick={() => handleLanguageChange('en')}
          className={`${style.selectDropdown__item}`}
        >
          English
        </div>
        <div
          onClick={() => handleLanguageChange('zh')}
          className={`${style.selectDropdown__item}`}
        >
          Chinese
        </div>
        <div
          onClick={() => handleLanguageChange('fr')}
          className={`${style.selectDropdown__item}`}
        >
          French
        </div>
        <div
          onClick={() => handleLanguageChange('de')}
          className={`${style.selectDropdown__item}`}
        >
          German
        </div>
        <div
          onClick={() => handleLanguageChange('hi')}
          className={`${style.selectDropdown__item}`}
        >
          Hindi
        </div>
        <div
          onClick={() => handleLanguageChange('it')}
          className={`${style.selectDropdown__item}`}
        >
          Italian
        </div>
        <div
          onClick={() => handleLanguageChange('ja')}
          className={`${style.selectDropdown__item}`}
        >
          Japanese
        </div>
        <div
          onClick={() => handleLanguageChange('ko')}
          className={`${style.selectDropdown__item}`}
        >
          Korean
        </div>
        <div
          onClick={() => handleLanguageChange('pt')}
          className={`${style.selectDropdown__item}`}
        >
          Portuguese
        </div>
        <div
          onClick={() => handleLanguageChange('ru')}
          className={`${style.selectDropdown__item}`}
        >
          Russian
        </div>
        <div
          onClick={() => handleLanguageChange('es')}
          className={`${style.selectDropdown__item}`}
        >
          Spanish
        </div>
      </div>
    </div>
  );
};

export default LangSelect;
