import style from './Related.module.scss';
import ArticleCard from '../../../../components/ArticleCard/ArticleCard';
import { articleList } from '../../articles/articles-list';
import { useLocation, useNavigate } from 'react-router-dom';

const RelatedMobile = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className={style.related}>
      <div className='container'>
        <div className={`${style.related__title} title_lg`}>
          related articles
        </div>
        <div className={style.relatedBody}>
          {articleList.map(
            (item, index) =>
              `/article/${item.path}` !== location.pathname && (
                <ArticleCard
                  key={index}
                  onClick={() => {
                    navigate(`/article/${item.path}`);
                  }}
                  img={item.img}
                  title={item.title}
                  className={style.relatedBody__item}
                />
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default RelatedMobile;
