
const TwitterSvg = () => <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_405_1080)">
    <path d="M26 5.40469C25.0703 5.8125 24.0797 6.08281 23.0469 6.21406C24.1094 5.57969 24.9203 4.58281 25.3016 3.38125C24.3109 3.97187 23.2172 4.38906 22.0516 4.62188C21.1109 3.62031 19.7703 3 18.3078 3C15.4703 3 13.1859 5.30313 13.1859 8.12656C13.1859 8.53281 13.2203 8.92344 13.3047 9.29531C9.04375 9.0875 5.27344 7.04531 2.74062 3.93438C2.29844 4.70156 2.03906 5.57969 2.03906 6.525C2.03906 8.3 2.95312 9.87344 4.31562 10.7844C3.49219 10.7687 2.68437 10.5297 2 10.1531C2 10.1688 2 10.1891 2 10.2094C2 12.7 3.77656 14.7688 6.10625 15.2453C5.68906 15.3594 5.23438 15.4141 4.7625 15.4141C4.43438 15.4141 4.10312 15.3953 3.79219 15.3266C4.45625 17.3562 6.34063 18.8484 8.58125 18.8969C6.8375 20.2609 4.62344 21.0828 2.22656 21.0828C1.80625 21.0828 1.40312 21.0641 1 21.0125C3.27031 22.4766 5.96094 23.3125 8.8625 23.3125C18.2937 23.3125 23.45 15.5 23.45 8.72812C23.45 8.50156 23.4422 8.28281 23.4312 8.06563C24.4484 7.34375 25.3031 6.44219 26 5.40469Z" fill="#383737" />
  </g>
  <defs>
    <clipPath id="clip0_405_1080">
      <rect width="25" height="25" fill="white" transform="translate(0.5 0.5)" />
    </clipPath>
  </defs>
</svg>

export default TwitterSvg